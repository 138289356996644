<template>
  <div>
   <div class="van-cell font-18 top ">
	   <van-icon name="friends-o" size="26" class="text-theme3 " />
	   <span class="custom-title">报名人数({{max_count}})</span>
   </div>
         
                 
    <div class="bot">
      <no-data v-if="List.length==0"></no-data>
      <van-pull-refresh  v-model="isloading" @refresh="onRefresh" v-else>
      <van-list
        v-model="loading"
        loading-text="加载中"
        :finished="finished"
		:offset="10"
        finished-text="加载完毕"
        @load="loadData()"
      >
        <div class="member-wrap van-cell" v-for="(item,index) in List" :key="index">
            <div class="left">
              <van-image round width="1.2rem" height="1.2rem" lazy-load :src="item.avatar" />
              <div class="name text-base font-16 clamp-1 ml10">{{item.nickname}}</div>
            </div>
          
            <!-- <div class="text text-grey text-base font-12 clamp-2">{{item.text}}</div> -->
        </div>
      </van-list>
      </van-pull-refresh> 
    </div>
  </div>
</template>

<script>
import { Icon,List, Cell, CellGroup,PullRefresh } from "vant";
import {activity_enroll_morelist} from '@/request/api';
export default {
  components: {
    [Icon.name]:Icon,
    [List.name]: List,
    [Cell.name]: Cell,
    [CellGroup.name]:CellGroup,
    [PullRefresh.name]:PullRefresh
  },
  data() {
    return {
      List: [],
	  per_page: 15,
	  current_page: 0,
	  has_more: true,
	  next_item: '',
	  max_count: 0,
      loading: false,
      finished: false,
      isloading:false
    };
  },
  methods: {
    async loadData(show) {  //show判断遮罩
      if(this.has_more == false){
        return false;
      }
      if(show){
         this.showLoading('加载中',true)
      }
      this.loading = true;
      
      let param = {
        id:this.$route.query.id,
		aid:this.$route.query.id,
        page:this.current_page + 1
      }
      
                               
      activity_enroll_morelist(param).then(res=>{
         if(show){
            this.hideLoading(); 
         }
            if(res.code == 1){  
				this.max_count = res.data.max_count
				
				
				this.per_page = res.data.per_page
				this.current_page = res.data.current_page
				this.has_more = res.data.has_more
				this.List =  this.List.concat(res.data.data) 
				
                 
                 this.loading = false;
                 
                 if(res.data.has_more ){
                   this.finished = false;
                 }else{
                   this.finished = true;
                 }
            }else{             
              this.$dialog({message:res.msg})
            }
      }).catch(err =>{         
         if(show){
           this.hideLoading();    
         }   
           this.$dialog({message:'请求有误,请重新刷新页面'})  
      })
     
    },
    onRefresh(){
      setTimeout(() => {
        this.$toast('刷新成功');
        this.isloading = false;    
      }, 500);
    }
  },
  mounted: function() {
    this.loadData(true);
  }
};
</script>

<style lang="less" >
//评论
.member-wrap {
  display: flex;
  flex: row nowrap;
  align-content: center;
  .left{
      flex: 5;
      display: flex;
      flex-flow: row nowrap;
      .name{
      flex: 3;
      line-height: 45px;
       }
      
  }
  
  .text{
      flex: 1;
      line-height: 45px;
  }
  
}
</style>>

